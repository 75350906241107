import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { useQueryParams } from '~/hooks/useQueryParams';
import * as linkActions from '~/actions/links';
import { useLocalStorage } from '~/hooks';
import { STORE_KEYS } from '~/consts/store';

export const DEFAULT_PAGE_SIZE_PAGINATED = 20;
// value to send to the backend to indicate that we do not
// wish to paginate
export const DEFAULT_PAGE_SIZE_NON_PAGINATED = 0;

// when pagination is not enabled, the backend will return this total value
export const NO_PAGINATION_TOTAL = -1;

export function useGetFlowListLinks() {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const queryParams = useQueryParams();

  const [storedKinds, setStoredKinds] = useLocalStorage(STORE_KEYS.KINDS, '');
  const [storedPage, setStoredPage] = useLocalStorage(STORE_KEYS.PAGE, 0);
  const [storedSearchString, setStoredSearchString] = useLocalStorage(STORE_KEYS.SEARCH, '');

  const { kinds = '', searchString = '', page = 0, siteAdminSearchString = '' } = queryParams;
  const kindsArray = useMemo(() => kinds.split(',').filter((str) => str), [kinds]);

  const [total, setTotal] = useState(NO_PAGINATION_TOTAL);
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);

  const setNewQueryParams = useCallback(
    (newParams) => {
      history.push({
        pathname,
        search: `?${new URLSearchParams({ ...queryParams, ...newParams })}`,
      });
    },
    [history, pathname, queryParams],
  );

  // whenever a regular search is done, reset the page
  const setSearch = useCallback(
    (searchValue) => {
      setStoredSearchString(searchValue);
      setNewQueryParams({ searchString: searchValue, page: 0 });
    },
    [setNewQueryParams, setStoredSearchString],
  );

  // whenever a siteadmin search is done, also reset the page
  const setSiteAdminSearch = (searchValue) => {
    setNewQueryParams({ siteAdminSearchString: searchValue, page: 0 });
  };

  const setKinds = useCallback(
    (newKinds = []) => {
      const areSameLength = newKinds.length === kindsArray.length;
      // don't trigger a needless useEffect if newKinds are set to the same values
      if (!areSameLength || !newKinds.every((kind) => kinds.includes(kind))) {
        setStoredKinds(newKinds.join(','));
        setNewQueryParams({ page: 0, kinds: newKinds.join(',') });
      }
    },
    [kinds, kindsArray.length, setNewQueryParams, setStoredKinds],
  );

  const setPage = useCallback(
    (newPage) => {
      setStoredPage(newPage);
      setNewQueryParams({ page: newPage });
    },
    [setNewQueryParams, setStoredPage],
  );

  const reset = useCallback(() => {
    setPage(0);
    history.push({
      pathname,
      search: `?${new URLSearchParams({})}`,
    });
  }, [pathname, history, setPage]);

  useEffect(() => {
    const fetchLinks = async () => {
      setLoading(true);
      if (siteAdminSearchString) {
        try {
          const {
            pagination: { total: apiResponseTotal },
          } = await dispatch(
            linkActions.searchLinks({
              page,
              pageSize: DEFAULT_PAGE_SIZE_PAGINATED,
              searchString,
              siteAdminSearchString,
              kinds: kindsArray,
            }),
          );
          setTotal(apiResponseTotal);
        } catch (_absorbedError) {
          setTotal(NO_PAGINATION_TOTAL);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const {
            pagination: { total: apiResponseTotal },
          } = await dispatch(
            linkActions.getLinks({
              page,
              pageSize: DEFAULT_PAGE_SIZE_PAGINATED,
              searchString,
              kinds: kindsArray,
            }),
          );
          setTotal(apiResponseTotal);
        } catch (_absorbedError) {
          setTotal(NO_PAGINATION_TOTAL);
        } finally {
          setLoading(false);
        }
      }
    };

    if (!initializing) {
      fetchLinks();
    }
  }, [dispatch, initializing, kindsArray, page, searchString, siteAdminSearchString]);

  // Initialize the query params from localStorage
  useEffect(() => {
    if (initializing && Object.keys(queryParams).length === 0) {
      setNewQueryParams({ kinds: storedKinds, page: storedPage, searchString: storedSearchString });
    }
    setInitializing(false);
  }, [initializing, queryParams, setNewQueryParams, storedKinds, storedPage, storedSearchString]);

  return {
    page,
    setPage,
    setSearch,
    setSiteAdminSearch,
    siteAdminSearchString,
    setKinds,
    pageSize: DEFAULT_PAGE_SIZE_PAGINATED,
    total,
    kinds: kindsArray,
    reset,
    searchString,
    loading,
    initializing,
  };
}
