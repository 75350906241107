import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Flex, Icon, tokens, NewTypography } from '@unitoio/mosaic';

import { SiteAdminContext } from '~/contexts';
import * as linkTypes from '~/consts/link';
import { getLinkById } from 'reducers';
import { getTestModeTermToUse } from '../../containers/FlowBuilder/hooks/useGetTestModeTerm';
import { getHasTestModeEnabled } from '~/containers/FlowBuilder/hooks/useHasTestModeEnabled';

const Wrapper = styled(Flex)`
  vertical-align: middle;
  a {
    color: ${tokens.colors.content.neutral.n40};
    &:hover {
      color: ${tokens.colors.content.info.default};
    }
  }
`;

const UnderText = styled(NewTypography.Text)`
  color: ${tokens.colors.content.neutral.n30};
`;

const Name = styled(NewTypography.Text)`
  display: inline-block;
  margin: ${tokens.spacing.s0};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(props) => (props.$shouldLeaveSpaceForLabel ? '290px' : '380px')};
  white-space: nowrap;
`;

const Tag = styled(NewTypography.Text)`
  display: inline-block;
  font-size: ${tokens.fontSize.f8};
  border-radius: 2rem;
  color: ${tokens.colors.content.info.default};
  background-color: ${tokens.colors.background.message.info};
  padding: ${tokens.spacing.s1} ${tokens.spacing.s3};
`;

const getLabel = ({ link, taskTermPlural }) => {
  const showTestModeTag = getHasTestModeEnabled(link);
  const testModeTerm = getTestModeTermToUse(link);
  const kind = link.get('kind');
  if (kind === linkTypes.KIND.TASK_SYNC) {
    return <Tag>mirror</Tag>;
  }

  if (showTestModeTag) {
    return (
      <Tag>
        Only {testModeTerm} {taskTermPlural}
      </Tag>
    );
  }

  return null;
};

export const LinkInformation = ({ linkId, taskTermPlural, editSyncUrl = null, hideLastSyncAt = false }) => {
  const link = useSelector((state) => getLinkById(state, linkId));

  const name = link.get('name');
  const user = link.get('user');
  const kind = link.get('kind');
  const restricted = link.get('restricted', false);
  const lastSyncRequest = link.get('lastSyncRequest');
  const showTestMode = getHasTestModeEnabled(link);

  const { isSiteAdmin } = useContext(SiteAdminContext);

  const canEdit = isSiteAdmin || !restricted;

  const hasLabel = showTestMode || kind === linkTypes.KIND.TASK_SYNC;

  let userId;
  // when converting a draft to a live flow, user is a string
  // because we don't deep populate the user after the conversion.
  if (typeof user === 'string') {
    userId = user;
  } else {
    userId = user?.get('_id');
  }

  return (
    <Wrapper align="start" justify="start" wrap="wrap" vertical style={{ marginRight: tokens.spacing.s4 }}>
      <Flex
        align="center"
        style={{ margin: `${tokens.spacing.s0} ${tokens.spacing.s3} ${tokens.spacing.s0} ${tokens.spacing.s0}` }}
        justify="flex-start"
        gap={tokens.spacing.s2}
        wrap="wrap"
      >
        {!linkId || !canEdit || !editSyncUrl ? (
          <Name $shouldLeaveSpaceForLabel={hasLabel}>{name}</Name>
        ) : (
          <Link to={editSyncUrl} title={`Edit the flow: ${name}`}>
            <Name $shouldLeaveSpaceForLabel={hasLabel}>{name}</Name>
          </Link>
        )}
        {getLabel({ link, taskTermPlural })}
      </Flex>
      {restricted && (
        <UnderText>
          Managed by <a href={`mailto:${user.get('email')}`}>{user.get('fullName')}</a>
        </UnderText>
      )}

      {isSiteAdmin && userId && (
        <Flex vertical wrap="wrap">
          <UnderText>
            id: <NewTypography.Text copyable>{linkId}</NewTypography.Text>
          </UnderText>
          <UnderText>
            userId: <NewTypography.Text copyable>{userId}</NewTypography.Text>
          </UnderText>
        </Flex>
      )}

      {!hideLastSyncAt && (
        <UnderText>
          <Icon name="history" kind={Icon.KINDS.SOLID} />
          Last sync: {lastSyncRequest ? moment(lastSyncRequest).fromNow() : 'Never'}
        </UnderText>
      )}
    </Wrapper>
  );
};

LinkInformation.propTypes = {
  linkId: PropTypes.string.isRequired,
  taskTermPlural: PropTypes.element,
  editSyncUrl: PropTypes.string,
  hideLastSyncAt: PropTypes.bool,
};
