import React, { useState, useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import ReactJson from 'react-json-view';

import { Collapse, Icon, Table, NewModal } from '@unitoio/mosaic';
import { logger } from '@unitoio/sherlock';

import * as containerActions from '~/actions/containers';
import * as fieldActions from '~/actions/fields';
import * as providerActions from '~/actions/providers';
import * as routes from '~/consts/routes';
import { color } from 'theme';
import { getLatestDiagnostic, getSelectedOrganizationId, isDiagnosticLoading } from 'reducers';
import { Card } from '~/components/Card/Card';
import { Href } from '~/components/Href/Href';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';
import * as linkActions from '~/actions/links';

import { LinkInfo } from './components/LinkInfo';
import { ConnectorInfo } from './components/ConnectorInfo';
import { organizationActions } from '~/actions';

export function fetchConnectorInfo(sync, dispatch) {
  const linkId = sync.get('_id');

  ['A', 'B'].forEach((containerSide) => {
    const requiredFields = ['providerIdentity._id', 'providerName', 'container.id', 'itemType'];
    const gettingValuesFromSync = requiredFields.map((path) => sync.getIn([containerSide, ...path.split('.')]));
    const fieldsAreSet = gettingValuesFromSync.every((field) => field !== undefined && field !== null);

    const [providerIdentityId, providerName, containerId, itemType] = gettingValuesFromSync;

    if (!fieldsAreSet) {
      logger.warn(`Missing required fields (side=${containerSide}) to fetch connector info. `, {
        containerId,
        itemType,
        providerIdentityId,
        providerName,
        linkId,
      });
      return;
    }

    dispatch(
      fieldActions.getCustomFields({
        containerId,
        containerSide,
        providerIdentityId,
        itemType,
        getUncached: true,
      }),
    );

    dispatch(
      providerActions.getProviderCapabilitiesForItem(providerName, providerIdentityId, containerId, itemType, linkId),
    );

    if (providerName === 'trello') {
      dispatch(
        containerActions.getContainerPlugins({
          containerId,
          providerIdentityId,
        }),
      ).catch((err) => {
        if (err.code !== 404) {
          throw err;
        }
      });
    }
  });
}

function useFetchSyncDiagnostics(sync) {
  const [taskCount, setTaskCount] = useState({ all: 0, closed: 0, filteredOut: 0 });
  const [isLoadingResources, setIsLoadingResources] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchResources = async () => {
      async function fetchTaskSyncCount() {
        const orgId = sync.getIn(['organization', '_id']) ?? sync.get('organization');
        const result = await dispatch(linkActions.getOrganizationTaskSyncsTaskCount(orgId, [sync.get('_id')]));

        setTaskCount(result.taskCount);
      }

      fetchConnectorInfo(sync, dispatch);

      await fetchTaskSyncCount();

      setIsLoadingResources(false);
    };

    fetchResources();
  }, [dispatch, sync]);

  return [taskCount, isLoadingResources];
}

export function useFetchOrganizationFeatureFlags(organizationId) {
  const dispatch = useDispatch();
  const selectedOrganizationId = useSelector((state) => getSelectedOrganizationId(state));
  const [featureFlags, setFeatureFlags] = useState();
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { flags } = await dispatch(organizationActions.getFlags(organizationId));
        setFeatureFlags(
          Object.entries(flags).map(([key, value]) => ({
            key,
            value: JSON.stringify(value),
          })),
        );
        setError(null);
      } catch (err) {
        setError(err);
        setFeatureFlags(undefined);
        logger.error('Failed to fetch organization flags', {
          error: err,
          organizationId,
        });
      }
    }

    fetchData();

    return () => {
      if (selectedOrganizationId !== organizationId) {
        dispatch(organizationActions.clearFlagsByOrganizationId(organizationId));
      }
    };
  }, [dispatch, organizationId, selectedOrganizationId]);

  return { featureFlags, error };
}

export function DiagnoseModal({ isOpen, onRequestClose, sync }) {
  const dispatch = useDispatch();

  const isLoading = useSelector(isDiagnosticLoading);
  const latestResult = useSelector(getLatestDiagnostic);
  const { featureFlags, error } = useFetchOrganizationFeatureFlags(sync.get('organization'));

  const [taskCount, isLoadingResources] = useFetchSyncDiagnostics(sync);
  const loading = isLoadingResources || !featureFlags || !error;

  function resetDiagnostic() {
    dispatch(linkActions.resetDiagnostic());
  }

  const linkId = sync.get('_id');
  const linkName = sync.get('name');

  return (
    <NewModal
      width="90%"
      open={isOpen}
      onCancel={onRequestClose}
      onClose={resetDiagnostic}
      loading={loading}
      footer={null}
    >
      <Section style={{ marginBottom: '24px' }}>
        <Card borderless color={color.dark.quiet} padding="1em">
          <Title type="h2">
            Diagnose <Href to={`${routes.ABSOLUTE_PATHS.FLOW_BUILDER_EDIT}/${linkId}`}>{linkName}</Href>
          </Title>
          <LinkInfo sync={sync} taskCount={taskCount} />
          <Collapse
            items={[
              {
                key: '1',
                label: 'Workspace Feature Flags',
                children: (
                  <Table
                    // context is weird here because it's an old modal so we need to force it
                    dataSource={featureFlags ?? []}
                    tableLayout="fixed"
                    locale={error ? { emptyText: error?.message } : undefined}
                    columns={[
                      {
                        title: 'Flag',
                        dataIndex: 'key',
                        filters: (featureFlags ?? []).map((item) => ({ text: item.key, value: item.key })),
                        onFilter: (value, record) => record.key === value,
                        filterMode: 'menu',
                        filterSearch: true,
                        filterDropdownProps: {
                          placement: 'bottomRight',
                          maxHeight: 400,
                        },
                      },
                      {
                        title: 'Value',
                        dataIndex: 'value',
                        sorter: (a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()),
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </Card>
      </Section>

      <Section>
        <ConnectorInfo sync={sync} />
      </Section>

      <Section>
        {isLoading && (
          <Card>
            <div className="text-center">
              <Icon name="spinner" size="5x" kind={Icon.KINDS.SOLID} title="loading" pulse />
            </div>
          </Card>
        )}
      </Section>

      <Section>
        <div className="row">
          {['A', 'B'].map((side) => (
            <div key={side} className="col-xs-6">
              <Card borderless color={color.dark.quiet} padding="1em">
                {latestResult?.[side] && (
                  <ReactJson
                    style={{ wordBreak: 'break-all', lineBreak: 'anywhere' }}
                    src={latestResult?.[side]}
                    displayDataTypes={false}
                  />
                )}
              </Card>
            </div>
          ))}
        </div>
      </Section>
    </NewModal>
  );
}

DiagnoseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  sync: PropTypes.instanceOf(Map).isRequired,
};
