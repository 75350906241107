import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Map, List } from 'immutable';
import styled from 'styled-components';
import { reduxForm, SubmissionError } from 'redux-form';

import { Box, Icon, tokens, ProviderIcon, Skeleton as InitSkeleton } from '@unitoio/mosaic';
import { ErrorBoundary } from '@unitoio/sherlock';

import * as authActions from '~/actions/auth';
import * as authTypes from '~/consts/auth';
import * as appTypes from '~/consts/app';
import * as providerTypes from '~/consts/providers';
import * as trackingTypes from '~/consts/tracking';
import {
  getProviderByName,
  getProviderByNamePreferredAuthMethod,
  getFieldValue,
  getIsLoadedProviders,
  getSelectedOrganizationId,
} from 'reducers';
import { color } from 'theme';
import { useQueryParams } from '~/hooks/useQueryParams';
import { useLogger } from '~/hooks/useLogger';
import { useTrackEvent } from '~/hooks/useTrackEvent';
import * as authUtils from '~/utils/auth';
import * as formUtils from '~/utils/forms';
import { getSearchParams } from '~/utils/getSearchParams';
import { Button } from '~/components/Button/Button';
import { Card } from '~/components/Card/Card';
import { KBArticle } from '~/components/KBArticle/KBArticle';
import { Section } from '~/components/Section/Section';
import { Title } from '~/components/Title/Title';
import { UnitoLogo } from '~/components/UnitoLogo/UnitoLogo';
import { AppError } from '~/containers/AppError/AppError';

import { AuthButtonsWrapper } from './AuthButtonsWrapper';
import { AuthConfigure } from './AuthConfigure';
import { AuthDomain } from './AuthDomain';
import { AuthInstructions } from './AuthInstructions';
import { AuthResult } from './AuthResult';
import { AuthTitleWrapper } from './AuthTitleWrapper';
import LinkToKB from './images/linkToKB.svg';

const LogoHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: ${tokens.spacing.s4};

  img {
    margin: 1rem;
  }
`;

const AuthSetupContainer = styled(Box)`
  min-height: 100vh;
`;

function getVisibleAuthorizationMethods(provider, isAuthenticating, validatedAuthorizationMethods) {
  const authorizationMethods = authUtils.getProviderAuthorizationMethods(provider);
  return authorizationMethods.filter((authMethod, authMethodType) => {
    if (validatedAuthorizationMethods?.length && !validatedAuthorizationMethods.includes(authMethodType)) {
      return false;
    }

    const supportedAuthActions = authMethod.get('supportedAuthActions', List());
    if (!isAuthenticating) {
      return supportedAuthActions.includes(authTypes.AUTH_ACTIONS.CONNECT);
    }

    return (
      supportedAuthActions.includes(authTypes.AUTH_ACTIONS.LOGIN) ||
      supportedAuthActions.includes(authTypes.AUTH_ACTIONS.SIGNUP)
    );
  });
}

function areRequiredFieldsMissing(values, provider, isAuthenticating, validatedAuthorizationMethods) {
  const authorizationMethods = getVisibleAuthorizationMethods(
    provider,
    isAuthenticating,
    validatedAuthorizationMethods,
  );
  const selectedMethod = authorizationMethods.get(values.authorizationMethod, Map());
  const missingRequiredFields = selectedMethod
    .get('fields', Map())
    .filter((field) => field.get('required'))
    .filter((field) => formUtils.isEmpty(values[field.get('fieldName')]));
  return !missingRequiredFields.isEmpty();
}

function AuthSetupInner({
  change,
  error,
  handleSubmit,
  history,
  location,
  match,
  provider,
  submitFailed,
  submitSucceeded,
  submitting,
  areProvidersLoaded,
  ...rest
}) {
  const domain = useSelector((state) => getFieldValue(state, 'domain', 'authentication'));
  const { reportException, reportWarning } = useLogger();
  const trackEvent = useTrackEvent({
    selected_tool_name: match.params.providerName,
    action_taken_from: match.params.method,
  });
  const dispatch = useDispatch();
  const { windowOpenerId = '' } = useQueryParams();
  const [providerRequiresSetup, setRequiresSetup] = useState(null);
  const [validatedAuthorizationMethods, setValidatedAuthorizationMethods] = useState([]);
  const isAuthenticating = [authTypes.AUTH_ACTIONS.LOGIN, authTypes.AUTH_ACTIONS.SIGNUP].includes(match.params.method);
  const connectionParameterFields = authUtils.getProviderConnectionParameterFields(provider);
  const visibleAuthenticationMethods = getVisibleAuthorizationMethods(
    provider,
    isAuthenticating,
    validatedAuthorizationMethods,
  );

  async function authenticate(providerName, values) {
    trackEvent(`SETUP_${trackingTypes.SUBMIT}`, {
      authorization_method: values.authorizationMethod,
      domainUrl: values.domain,
    });
    const queryParams = getSearchParams(location.search);
    const authenticationAction = match.params.method;
    const redirect = location.state;

    try {
      const { authenticationUrl } = await dispatch(
        authActions.authenticate({
          authenticationAction,
          authenticationTool: providerName,
          authorizationMethod: values.authorizationMethod,
          authenticationOptions: {
            apiUrl: values.apiUrl,
            clientId: values.clientId,
            clientSecret: values.clientSecret,
            domain: values.domain,
            password: values.apiToken || values.password,
            selfSignedCertificate: values.selfSignedCertificate,
            username: values.username,
            popup: window.opener ? windowOpenerId : undefined,
            includesPreAuthentication: true, // Will this always be the case with connect with github
          },
          authenticationUserProfile: {
            productName: appTypes.PRODUCT_NAMES.PROJECT_SYNC,
            keepInformed: queryParams.keepInformed,
          },
          redirect,
        }),
      );

      global.window.location.assign(authenticationUrl);
    } catch (err) {
      trackEvent(trackingTypes.BLOCKED, {
        domainUrl: values.domain,
        reason: err.message,
      });
      reportException(err, { identifier: 'authenticationError authenticate AuthSetup' });
      if (providerName.includes('jira') && err.message?.includes("haven't set up an Application Link")) {
        throw new SubmissionError({
          _error: (
            <>
              It seems like you haven't set up an Application Link in your Jira account. Be sure to{' '}
              <a target="_blank" rel="noreferrer" href="https://guide.unito.io/how-to-configure-jira-for-unito-access">
                configure Jira first so that Unito can have access.
              </a>
            </>
          ),
        });
      } else {
        throw new SubmissionError({ _error: err.message });
      }
    }
  }

  function handleCancel() {
    // If the window is in the context of a popup window, close the window on cancel
    if (window.opener) {
      window.close();
    } else {
      history.push(`/${match.params.method}`);
    }
  }

  async function handleValidateDomain(values) {
    trackEvent('ENTER-URL_SUBMIT', { domainUrl: values.domain });
    let response;
    try {
      response = await dispatch(authActions.validateDomain(provider.get('name'), values.domain));
    } catch (err) {
      reportException('Unexpected error', { identifier: 'invalidDomain handleValidateDomain AuthSetup', err });
      throw new SubmissionError({
        _error: 'UNKNOWN',
      });
    }

    const { valid, errorMessage, correctedDomain, requiresSetup, authorizationMethod, availableAuthorizationMethods } =
      response;

    if (!valid) {
      trackEvent(trackingTypes.BLOCKED, {
        domainUrl: domain,
        reason: errorMessage,
        failedValidation: true,
        requiresSetup,
      });
      // Using reportWarning instead of reportException because the error message is the message returned by the provider meaning that it is not our service that failed.
      reportWarning(errorMessage, { identifier: 'invalidDomain handleValidateDomain AuthSetup' });
      throw new SubmissionError({ _error: errorMessage });
    }

    change('domain', correctedDomain);

    if (availableAuthorizationMethods?.length) {
      setValidatedAuthorizationMethods(availableAuthorizationMethods);
      if (!availableAuthorizationMethods.includes(values.authorizationMethod)) {
        // The authorization method was the default recommended by the connector,
        // but it's not available for this domain
        change('authorizationMethod', availableAuthorizationMethods[0]);
      }
    }

    setRequiresSetup(requiresSetup);
    if (requiresSetup) {
      history.push(`${match.url}/configure?windowOpenerId=${windowOpenerId}`);
      return;
    }

    let configuredValues;
    if (authorizationMethod) {
      // The authorization method was the default recommended by the connector,
      // but the instance might be configured with a different one
      change('authorizationMethod', authorizationMethod);
      configuredValues = { ...values, authorizationMethod };
    }
    await authenticate(provider.get('name'), configuredValues || values, windowOpenerId);
  }

  async function testConnectionAndAuthenticate(values) {
    if (areRequiredFieldsMissing(values, provider, isAuthenticating)) {
      history.replace(match.url);
      return;
    }

    if (!authUtils.getProviderConnectionParameters(provider).isEmpty()) {
      const { password, clientSecret, ...trackableValues } = values;
      try {
        const { valid, errorMessage } = await dispatch(
          authActions.testConnection(provider.get('name'), {
            domain: values.domain,
            selfSignedCertificate: values.selfSignedCertificate,
          }),
        );

        if (!valid) {
          throw new Error(errorMessage);
        }
      } catch (err) {
        trackEvent(trackingTypes.BLOCKED, {
          ...trackableValues,
          reason: err.message,
          errorName: err.name,
          failedValidation: true,
        });
        // Using reportWarning instead of reportException because the error message is the message returned by the provider meaning that it is not our service that failed.
        reportWarning(err.message, { identifier: 'invalidConnection testConnectionAndAuthenticate AuthSetup' });
        throw new SubmissionError({ _error: err.message, selfSignedCertificate: err.message });
      }
    }

    const locationProviderName = match.params?.providerName;
    await authenticate(provider.get('name') || locationProviderName, values, windowOpenerId);
  }

  return (
    <InitSkeleton loading={!areProvidersLoaded} avatar paragraph round title={false}>
      <AuthSetupContainer alignItems="center" p={[tokens.spacing.s6, 0]} className="container">
        <form className="col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2" onSubmit={(e) => e.preventDefault()}>
          <Card color={color.light.primary} padding="3rem 4rem" margin="5rem 0" borderRadius={tokens.spacing.s4}>
            <LogoHeader>
              {match.params.providerName !== providerTypes.AUTH0 && (
                <>
                  <ProviderIcon name={provider.get('name')} size="xlarge" />
                  <Icon name="plus" size="2x" kind={Icon.KINDS.SOLID} title="add" />
                </>
              )}
              <UnitoLogo kind="mark" width="60px" />
            </LogoHeader>
            <Route
              {...rest}
              exact
              path={[match.path, `${match.path}/`]}
              render={(routeProps) => {
                if (connectionParameterFields.isEmpty()) {
                  return <Redirect to={`${match.url}/configure?windowOpenerId=${windowOpenerId}`} />;
                }

                return (
                  <Section>
                    <AuthTitleWrapper>
                      <Title type="h2" align="center">
                        Connect {provider.get('displayName')} with Unito
                      </Title>
                    </AuthTitleWrapper>
                    <AuthDomain
                      {...routeProps}
                      provider={provider}
                      isTested={submitFailed || submitSucceeded}
                      error={error}
                    />
                    <AuthButtonsWrapper className="clearfix">
                      <Button
                        pullRight
                        btnStyle="dark"
                        onClick={handleSubmit(handleValidateDomain)}
                        disabled={submitting || !domain?.trim().length}
                      >
                        Continue
                      </Button>
                      {/* eslint-disable-next-line react/jsx-no-bind */}
                      <Button reverse btnStyle="dark" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </AuthButtonsWrapper>
                  </Section>
                );
              }}
            />
            <Route
              {...rest}
              exact
              path={`${match.path}/configure`}
              render={(routeProps) => (
                <Section>
                  <AuthTitleWrapper>
                    <Title type="h2" align="center">
                      Connect {provider.get('displayName')} with Unito
                    </Title>
                  </AuthTitleWrapper>
                  {!connectionParameterFields.isEmpty() && (
                    <AuthDomain
                      {...routeProps}
                      provider={provider}
                      isTested={submitFailed || submitSucceeded}
                      error={error}
                    />
                  )}
                  <AuthConfigure
                    {...routeProps}
                    provider={provider}
                    windowOpenerId={windowOpenerId}
                    requiresSetup={authUtils.providerNeedsManualConfiguration(provider) || providerRequiresSetup}
                    visibleAuthorizationMethods={visibleAuthenticationMethods}
                    testConnectionAndAuthenticate={handleSubmit(testConnectionAndAuthenticate)}
                  />
                </Section>
              )}
            />
            <Route
              {...rest}
              path={`${match.path}/configure/instructions`}
              render={(routeProps) => (
                <AuthInstructions
                  {...routeProps}
                  error={error}
                  onCancel={handleCancel} // eslint-disable-line react/jsx-no-bind
                  onSubmit={handleSubmit(testConnectionAndAuthenticate)}
                  provider={provider}
                  trackEvent={trackEvent}
                />
              )}
            />
            <Route
              {...rest}
              exact
              path={`${match.path}/:result(success|failure)`}
              render={(routeProps) => (
                <AuthResult
                  {...routeProps}
                  provider={provider}
                  onCancel={handleCancel} // eslint-disable-line react/jsx-no-bind
                  onSubmit={handleSubmit(testConnectionAndAuthenticate)}
                  trackEvent={trackEvent}
                />
              )}
            />
          </Card>
          <Route
            {...rest}
            exact
            path={`${match.path}/success`}
            render={() => {
              const articleURL = provider.getIn(['capabilitiesV3', 'unitoArticleURL']);
              if (!articleURL) {
                return null;
              }
              return (
                <KBArticle
                  title={`A Primer on Unito’s ${provider.get('displayName')} Integration`}
                  content={`With Unito's ${provider.get(
                    'displayName',
                  )} integration, you can pull crucial data and plug it into any other tool in your stack.`}
                  linkText="Read our article"
                  articleURL={articleURL}
                  image={LinkToKB}
                  providerName={provider.get('name')}
                />
              );
            }}
          />
        </form>
      </AuthSetupContainer>
    </InitSkeleton>
  );
}

function AuthSetupWithErrorBoundary(props) {
  const { reportException } = useLogger();
  return (
    <ErrorBoundary
      FallbackComponent={AppError}
      onError={(error, { componentStack }, errMessageContext) =>
        reportException(error, { ...errMessageContext, componentStack })
      }
    >
      <AuthSetupInner {...props} />
    </ErrorBoundary>
  );
}

AuthSetupInner.propTypes = {
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({ search: PropTypes.string.isRequired, state: PropTypes.string.isRequired }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      providerName: PropTypes.string.isRequired,
      method: PropTypes.oneOf(Object.values(authTypes.AUTH_ACTIONS)).isRequired,
    }),
  }).isRequired,
  provider: PropTypes.instanceOf(Map).isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  areProvidersLoaded: PropTypes.bool.isRequired,
};

const AuthSetupComponent = reduxForm({
  form: 'authentication',
  enableReinitialize: true,
})(AuthSetupWithErrorBoundary);

const mapStateToProps = (state, props) => {
  const provider = getProviderByName(state, props.match.params.providerName);
  const query = getSearchParams(props.location.search);
  const authenticationOptions = query.authenticationParams?.authenticationOptions || {};
  const organizationId = getSelectedOrganizationId(state);
  const areProvidersLoaded = getIsLoadedProviders(state, organizationId);

  return {
    provider,
    areProvidersLoaded,
    initialValues: {
      authorizationMethod: getProviderByNamePreferredAuthMethod(state, provider.get('name')),
      ...authenticationOptions,
    },
  };
};

export const AuthSetup = connect(mapStateToProps)(AuthSetupComponent);
